
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'stipenddistrict',
  components: {
    Field,
  },
  data() {
    return {
      stipendDistricts: [] as any,
      load: false,
      totaldistrict: 0 as any,
      belowpoor: 0 as any,
      upperpoor: 0 as any,
    };
  },
  async created() {
    this.load = true;
    this.emitter.on('stipend_district_data', async (data) => {
      this.stipendDistricts = data;
      this.belowpoor = 0 as any;
      this.upperpoor = 0 as any;
      for (let i = 0; i < this.stipendDistricts.length; i++) {
        if (this.stipendDistricts[i]?.is_poor == 1) {
          this.belowpoor += 1;
        } else {
          this.upperpoor += 1;
        }
      }
    });
  },
  methods: {},
});
