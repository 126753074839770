
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { ScrollComponent } from "@/assets/ts/components/_ScrollComponent";
import { MenuComponent } from "@/assets/ts/components/MenuComponent";
import { version } from "@/core/helpers/documentation";
import { asideMenuIcons } from "@/core/helpers/config";
import MainMenuConfig from "./Menu";
import { VueCookieNext } from "vue-cookie-next";
export default defineComponent({
name: "kt-menu",
 data() {
    return {
      MainMenuConfig: [
        {
         heading: "menu",
         route: "/stipend",
         pages: [
          {
            heading: "Stipend Eligibility Check ",
            route: "/stipend/stipend-eligibility",
            fontIcon: "fa-user",
            svgIcon: "media/icons/duotune/general/gen019.svg",
          },
          // {
          //   heading: "Trainee Migration",
          //   route: "/stipend/trainee-migration",
          //   fontIcon: "fa-user-secret",
          //   svgIcon: "media/icons/duotune/general/gen019.svg",
          // },
          {
            heading: "Trainee Selection",
            route: "/stipend/trainee-selection",
            fontIcon: "fa-user-secret",
            svgIcon: "media/icons/duotune/general/gen019.svg",
          },
          {
            heading: "Selected Trainee",
            route: "/stipend/selected-trainee",
            fontIcon: "fa-user-secret",
            svgIcon: "media/icons/duotune/general/gen019.svg",
          },
          {
            heading: "Upload Disbursement Data",
            route: "/stipend/disbursement-data",
            fontIcon: "fa-user-secret",
            svgIcon: "media/icons/duotune/general/gen019.svg",
          },
          {
              sectionTitle: 'Stipend Management Report',
              svgIcon: 'media/icons/duotune/general/gen025.svg',
              fontIcon: 'bi-app-indicator',
              sub: [
                {
                  heading: 'Disbursement Report',
                  route: '/stipend/disbursement-report',
                },
                {
                  heading: 'Stipend Payment Summary',
                  route: '/stipend/stipend-payment-summary',
                },
                {
                  heading: 'Stipend Activity Report (By Association)',
                  route: '/stipend/stipend-activity-report-association',
                },
                {
                  heading: 'Stipend Activity Report(By District)',
                  route: '/stipend/stipend-activity-report-district',
                },
              ],
          },
          // {
          //   heading: "Disbursement Report",
          //   route: "/stipend/disbursement-report",
          //   fontIcon: "fa-user-secret",
          //   svgIcon: "media/icons/duotune/general/gen019.svg",
          // },
          // {
          //   heading: "Stipend Payment Summary",
          //   route: "/stipend/stipend-payment-summary",
          //   fontIcon: "fa-user-secret",
          //   svgIcon: "media/icons/duotune/general/gen019.svg",
          // },
          // {
          //   heading: "Stipend Activity Report (By Association)",
          //   route: "/stipend/stipend-activity-report-association",
          //   fontIcon: "fa-user-secret",
          //   svgIcon: "media/icons/duotune/general/gen019.svg",
          // },
          // {
          //   heading: "Stipend Activity Report(By District)",
          //   route: "/stipend/stipend-activity-report-district",
          //   fontIcon: "fa-user-secret",
          //   svgIcon: "media/icons/duotune/general/gen019.svg",
          // },
         ],
        },
      ],
    };
  },
  components: {},
  async created() {
    await this.menucheck();
  },
  methods: {
    async menucheck() {
      let entity_type_id = VueCookieNext.getCookie("_seip_entity_type");
      if (entity_type_id != 1004) {
        this.MainMenuConfig = [
          {
            heading: "menu",
            route: "/stipend",
            pages: [
              {
                heading: "Stipend Eligibility Check ",
                route: "/stipend/stipend-eligibility",
                fontIcon: "fa-user",
                svgIcon: "media/icons/duotune/general/gen019.svg",
              },
            ],
          },
        ];
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const scrollElRef = ref<null | HTMLElement>(null);

    onMounted(() => {
      ScrollComponent.reinitialization();
      MenuComponent.reinitialization();
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }
    });

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    const hasActiveChildren = (match) => {
      return route.path.indexOf(match) !== -1;
    };

    return {
      hasActiveChildren,
      asideMenuIcons,
      version,
      translate,
    };
  },
});
