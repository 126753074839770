
//import ApiService from "@/core/services/ApiService";
import { defineComponent } from 'vue';
import { Field } from 'vee-validate';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
import * as Yup from 'yup';
import { DrawerComponent } from '@/assets/ts/components/_DrawerComponent';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiEndpoint';

export default defineComponent({
  mixins: [apiEndpoint],
  name: 'stipend-course',
  components: {
    Field,
  },
  data() {
    return {
      courseList: [] as any,
      load: false,
    };
  },
  async created() {
    this.load = true;
    this.emitter.on('stipend_course_data', async (data) => {
      this.courseList = data;
    });
  },
  methods: {},
});
